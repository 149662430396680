import React, { useState } from "react";

const glossary = [
    {
        "login" : "user",
        "password": "12345"
    },
    {
        "login" : "admin",
        "password": "admin123"
    },
    {
        "login" : "sudo",
        "password": "sudosudo"
    },
]
const LoginForm = () => {
    const [authState, setAuthState] = useState("auth")

    const handleLogin = () => {
        let login = document.getElementById("login_field").value
        let password = document.getElementById("password_field").value
        let found = false
        glossary.map((user) => {
            if(user.login === login){
                found = true
                if(user.password === password){
                    setAuthState("success")
                } else{
                    setAuthState("failed")
                }
            }
            if(found === false){
                setAuthState("failed")
                found = true
            }
        })
    }

    if(authState === "auth"){
        return(
            <>
                <div className='login_form_placeholder'>
                    <form className='login_form_form'>
                        <input id={"login_field"}/>
                        <input id={"password_field"} />
                        <button type={"button"} onClick={handleLogin}>Go</button>
                    </form>
                </div>
            </>
        )
    } else if(authState === "success"){
        return(
            <>
                <h1>Success</h1>
            </>
        )
    } else{
        return(
            <>
                <h1>Failed</h1>
            </>
        )
    }

}

export default LoginForm;