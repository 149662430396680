import React from "react";
import './Admin.css';
import LoginForm from "./LoginForm";

const Admin = () => {
    return(
        <>
            <LoginForm />
        </>
    )
}

export default Admin;