import React, {useRef} from "react";
import emailjs from '@emailjs/browser';


const HomeEN = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_afkyigy', 'template_g4mbb6h', form.current, {
                publicKey: 'FJFzQYTyNDguK4qdD',
            })
            .then(
                () => {
                    alert('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return(<>
    <div style={{
        color: 'white'
    }}>
        <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
        </form>
    </div>
    </>)
}

export default HomeEN;