import './App.css';
import HomeUA from "./components/Home/HomeUA";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./components/Admin/Admin";
import Redirect from "./components/Redirect";
import HomeEN from "./components/Home/HomeEN";
import HomeTR from "./components/Home/HomeTR";


function App() {

    if(window.location.pathname === '/ua'){
        window.localStorage.setItem('lng', 'ua')
    } else if(window.location.pathname === '/tr'){
        window.localStorage.setItem('lng', 'tr')
    } else if(window.location.pathname === '/en'){
        window.localStorage.setItem('lng', 'en')
    }

    if(window.location.pathname === '/'){
        if(window.localStorage.getItem("lng") === "en"){
            window.location.replace('/en')
        } else if(window.localStorage.getItem("lng") === "ua"){
            window.location.replace('/ua')
        } else if(window.localStorage.getItem("lng") === "tr"){
            window.location.replace('/tr')
        } else{
            window.localStorage.setItem('lng', 'en')
            window.location.replace('/en')
        }
    } else{

            return (
                <BrowserRouter>
                    <Routes>
                        <Route path='/en' element={<HomeEN/>}/>
                        <Route path='/tr' element={<HomeTR/>}/>
                        <Route path='/ua' element={<HomeUA/>}/>
                        <Route path='/administration' element={<Admin/>}/>
                        <Route path='*' element={<Redirect/>}/>
                    </Routes>
                </BrowserRouter>
            )

    }

}

export default App;
