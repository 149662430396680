import React, {useEffect, useState, compo, useRef} from "react";
import {Button, Carousel, Flex, Menu} from "antd";
import image from './img.png'
import image2 from './img2.jpg'
import image3 from './img3.jpg'
import image4 from './img4.png'
import image5 from './img5.png'
import image6 from './img6.jpg'
import image7 from './img7.jpg'
import img8 from './img8.jpg'
import img10 from './IMG_5271.JPG'
import img11 from './IMG_5272.JPG'
import img12 from './IMG_5273.JPG'
import img13 from './IMG_5276.JPG'
import ua from './ua.svg'
import gb from './gb.svg'
import tr from './tr.svg'
import menuImg from './menu.svg'
import closeImg from './close.svg'
import col1 from './col1.png'

import './animations.js'

import {AttentionSeeker, Fade} from "react-awesome-reveal";

import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from "@emailjs/browser";

const HomeUA = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_afkyigy', 'template_g4mbb6h', form.current, {
                publicKey: 'FJFzQYTyNDguK4qdD',
            })
            .then(
                () => {
                    setFeedbackForm(false)
                    setFeedbackDone(true)
                },
                (error) => {
                    setFeedbackForm(false)
                },
            );
    };

    const [loaded, setLoaded] = useState(false)
    const [preloaderHide, setPreloaderHide] = useState(false)

    const [device, setDevice] = useState('')

    const [languageSelector, setLanguageSelector] = useState(false)
    const [feedbackForm, setFeedbackForm] = useState(false)
    const [feedbackDone, setFeedbackDone] = useState(false)

    const [languageSelectorMobile, setLanguageSelectorMobile] = useState(false)
    const [feedbackFormMobile, setFeedbackFormMobile] = useState(false)

    const [menu, setMenu] = useState(false)

    const [card1Mobile, setCard1Mobile] = useState(false)

    const deviceTimer = setInterval( () => {
        if(window.innerWidth > 769){
            setDevice('desktop')
        } else {
            setDevice('tablet')
        }
    }, 100);

    useEffect(() => {
        setTimeout(() => {
            setPreloaderHide(true)
        }, "3000");
        const onPageLoad = () => {
            setLoaded(true)
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const handleScroll = () => {
        // if(window.scrollY < 4800){
        //     document.getElementById('photoHolder').classList.remove('freeze')
        // }
        // if(window.scrollY > 4800){
        //     document.getElementById('photoHolder').classList.add('freeze')
        // }
        //
        // if(window.scrollY > 10800){
        //     document.getElementById('photoHolder').classList.remove('freeze')
        //     document.getElementById('div2').classList.add('freeze2')
        // }

        // if(window.scrollY > 14000){//!!!                                                                           !!!
        //     document.getElementById('div2').classList.remove('freeze2')
        // }

        // if(window.scrollY > 10000){
        //     document.getElementById('hide1').classList.add('hide')
        // } else if(window.scrollY < 10000){
        //     document.getElementById('hide1').classList.remove('hide')
        // }

        //disappear1 animation
        //
        // if(window.scrollY > 8400 && window.scrollY < 12000){
        //     document.getElementById('disappear1').classList.remove('anim-disappear')
        //     document.getElementById('disappear1').classList.add('anim-reveal')
        // }
        // if(window.scrollY > 12000){
        //     document.getElementById('disappear1').classList.add('anim-disappear')
        //     document.getElementById('disappear1').classList.remove('anim-reveal')
        // }




    }

    useEffect(() => {
        const el = window;
        el.addEventListener("scroll", handleScroll, {
            passive: true
        });
        return () => el.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        AOS.init();
    }, [])

    const [cardFlipped, setCardFlipped] = useState(false)
    const [card2Flipped, setCard2Flipped] = useState(false)


    const handleLanguage = () => {
        setLanguageSelector(true)
    }

    const handleLanguageMobile = () => {
        setLanguageSelectorMobile(true)
    }

    const handleLanguageMobileClose = () => {
        setLanguageSelectorMobile(false)
    }

    const handleLanguageClose = () => {
        setLanguageSelector(false)
    }

    const handleFeedback = () => {
        setFeedbackForm(true)
    }
    const handleFeedbackClose = () => {
        setFeedbackForm(false)
        setFeedbackDone(false)
    }

    const handleFeedbackMobileClose = () => {
        setFeedbackFormMobile(false)
        // setFeedbackDone(false)
    }

    const handleCard1Flip = () => {
        document.getElementById('card1Body').classList.add('rotate')
        setCardFlipped(true);
    }
    const handleCard1UnFlip = () => {
        document.getElementById('card1Body').classList.remove('rotate')
        setCardFlipped(false);
    }
    const card1Engine = () => {
        if(cardFlipped === false) {
            return (
                <>
                                        <span className='rotateContent' style={{
                                            fontSize: '2vw',
                                            textAlign: 'left',
                                            width: '100%',
                                            fontFamily: 'Russo One, Sans-serif',
                                            textTransform: 'uppercase',
                                            fontWeight: '800',
                                            color: 'black'
                                            // color: '#5A5A5A',
                                        }}>Системний аудит</span> <br />

                    <span className='rotateContent' style={{
                        fontSize: '1.3vw',
                        fontFamily: 'Russo One, Sans-serif',
                        // color: '#5A5A5A',
                        textAlign: 'left',
                        color: 'black'
                    }}>
                            <span style={{ fontSize: '1.1em', fontWeight: '600' }}>Збільшення доходу компанії за рахунок виявлення та автоматизації процесів, що цього потребують.</span>
                        </span>
                    <div style={{ height: '100%' }} />
                    <Button className='rotateContent' onClick={handleCard1Flip} style={{
                        fontSize: '1.3vw',
                        fontFamily: 'Russo One, Sans-serif',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        width: '11em',
                        height: '2em',
                        border: '2px solid black',
                        color: 'black'
                    }} shape='round'>Дізнатись більше</Button>
                </>
            )
        } else if( cardFlipped === true ){
            return(
                <div style={{
                    textAlign: 'left'
                }} className="rotateContent mirrored">
                    <span className='rotateContent' style={{
                        fontSize: '1.4vw',
                        fontFamily: 'Russo One, Sans-serif',
                        // color: '#5A5A5A',
                        textAlign: 'left',
                        color: 'black'
                    }}>
                            <span style={{ fontSize: '1.1em', fontWeight: '600' }}>
                            Наш менеджер залюбки зв'яжеться з вами та проконсультує вас щодо будь-яких питань.
                            </span>
                        </span>
                    <div style={{ height: '100%' }} />
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '7.5vw'
                    }}>
                        <Button onClick={handleFeedback} className='rotateContent' style={{
                            fontSize: '1.3vw',
                            fontFamily: 'Russo One, Sans-serif',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            width: '11em',
                            height: '2em',
                            border: '2px solid black',
                            color: 'black'
                        }} shape='round'>замовити аудит</Button>
                        <Button onClick={handleCard1UnFlip} style={{
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            border: 'none',
                            color: 'black',
                            boxShadow: 'none',
                            fontSize: '1.7vw',
                            width: '1em',
                            marginTop: '0.2em',
                            marginLeft: '9em'
                        }}>✕</Button>
                    </div>
                </div>
            )
        }
    }

    const cards = () => {
        if(document.getElementById('trigger1') !== null) {
            console.log(document.getElementById('trigger1').getBoundingClientRect().top)
            if(document.getElementById('trigger1').getBoundingClientRect().top < 900){
                document.getElementById('card1Body').classList.add('release')
            }
        }
        return(

                <div style={{
                    width: '100%',
                    height: '60vh',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    <div id='card1Body' style={{
                        position: 'absolute',
                        left: '-5em',
                        background: 'linear-gradient(to right, #edf1f5, #eef2f3)',
                        backgroundSize: 'cover',
                        width: '37vw',
                        height: '55vh',
                        borderRadius: '1vw',
                        zIndex: '2',
                        marginTop: '-6vh',
                        textAlign: 'center',
                        padding: '3em',
                        paddingTop: '3em',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '1em'
                    }}>
                        { card1Engine() }


                        {/*<span style={{*/}
                        {/*    fontSize: '1.4em',*/}
                        {/*    fontFamily: 'Russo One, Sans-serif',*/}
                        {/*    // color: '#5A5A5A',*/}
                        {/*    textAlign: 'left'*/}
                        {/*}}>*/}
                        {/*    <span style={{ fontSize: '1.2em', fontWeight: '550' }}>Переваги системного аудиту</span><br/><span style={{ fontWeight: '550' }}>Виявлення</span> слабких місць і рекомендації щодо їх усунення.<br/>*/}
                        {/*    <span style={{ fontWeight: '550' }}>Ідентифікація і мінімізація</span> ризиків, пов'язаних з діяльністю організації.<br />*/}
                        {/*    <span style={{ fontWeight: '550' }}>Підтвердження</span> прозорості і надійності систем управління організації для зацікавлених сторін.*/}
                        {/*</span>*/}
                    </div>
                    <div id='card2Body' style={{
                        position: 'absolute',
                        right: '-5em',
                        backgroundImage: 'url(' + img12 + ')',
                        backgroundSize: 'cover',
                        width: '39vw',
                        height: '55vh',
                        borderRadius: '20px',
                        zIndex: '1',
                        marginTop: '5em'
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '20px',
                        }}>

                        </div>
                    </div>
                    {/*<div style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    width: '100vw',*/}
                    {/*    height: '100%',*/}
                    {/*    opacity: '0.5',*/}
                    {/*    marginTop: '-15vh',*/}
                    {/*    background: 'url(https://img.freepik.com/free-photo/smooth-elegant-transparent-blue-cloth-separated-blue-background_155003-15811.jpg?t=st=1725962263~exp=1725965863~hmac=4255440e7c453de998edc6208c1cc6161bccf7ba712e7bbd7491e0e731ad5dd7&w=2000)',*/}
                    {/*    backgroundSize: 'cover',*/}
                    {/*}}>*/}

                    {/*</div>*/}
                    <div className='tl12' style={{
                        position: 'absolute',
                        width: '100vw',
                        height: '79%',
                        opacity: '1',
                        marginTop: '1.5vh',
                        background: 'black',
                        backgroundSize: 'cover'
                    }}>

                    </div>
                </div>

        )
    }

    const fetchPreloader = () => {
        if (loaded === true && preloaderHide === true) {
            return (
                <div id='preloader-body' className='preloader-disappear' style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: '0',
                    zIndex: '9999'
                }}>
                    <span id='preloader' style={{
                        fontSize: '7em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.4em',
                        background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59, #c471ed, #12c2e9)',
                        backgroundClip: 'text',
                        backgroundSize: '200% auto',
                        color: 'transparent'
                    }}>AKSON</span>
                </div>
            )
        } else{
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: '0',
                    zIndex: '9999'
                }}>
                    <span id='preloader' style={{
                        fontSize: '7em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.4em',
                        background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59, #c471ed, #12c2e9)',
                        backgroundClip: 'text',
                        backgroundSize: '200% auto',
                        color: 'transparent'
                    }}>AKSON</span>
                </div>
            )
        }
    }

    const fetchPreloaderMobile = () => {
        if (loaded === true && preloaderHide === true) {
            return (
                <div id='preloader-body' className='preloader-disappear' style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: '0',
                    zIndex: '9999'
                }}>
                    <span id='preloader' style={{
                        fontSize: '3em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.4em',
                        background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59, #c471ed, #12c2e9)',
                        backgroundClip: 'text',
                        backgroundSize: '200% auto',
                        color: 'transparent',
                        textAlign: 'center',
                        marginLeft: '0.4em'
                    }}>AKSON</span>
                </div>
            )
        } else{
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: '0',
                    zIndex: '9999'
                }}>
                    <span id='preloader' style={{
                        fontSize: '3em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.4em',
                        background: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59, #c471ed, #12c2e9)',
                        backgroundClip: 'text',
                        backgroundSize: '200% auto',
                        color: 'transparent',
                        textAlign: 'center',
                        marginLeft: '0.4em'
                    }}>AKSON</span>
                </div>
            )
        }
    }

    const languageSelectorModal = () => {
        if(languageSelector === true) {
            return (
                <div data-aos="fade" style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div onClick={handleLanguageClose} style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute'
                    }}>
                    </div>
                    <div data-aos="fade-up" style={{
                        width: '33vw',
                        height: '14vw',
                        background: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        zIndex: '99999999'
                    }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2.4vw',
                            marginTop: '1vw'
                        }}>
                            Оберіть вашу мову
                        </span>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <a style={{
                                margin: '1.5vw'
                            }} href='/ua'>
                                <img src={ua} width='130vw'/>
                            </a>
                            <a style={{
                                margin: '1.5vw'
                            }} href='/en'>
                                <img src={gb} width='130vw'/>
                            </a>
                            <a style={{
                                margin: '1.5vw'
                            }} href='/tr'>
                                <img src={tr} width='130vw'/>
                            </a>
                        </div>
                    </div>
                </div>

            )
        }
    }

    const languageSelectorModalMobile = () => {
        if(languageSelectorMobile === true) {
            return (
                <div onClick={handleLanguageMobileClose} data-aos="fade" style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                }}>
                    {/*<div style={{*/}
                    {/*    width: '100vw',*/}
                    {/*    height: '100vh',*/}
                    {/*    position: 'absolute'*/}
                    {/*}}>*/}
                    {/*</div>*/}
                    <div data-aos="fade-down" style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        alignItems: 'center',
                        marginTop: '6vh'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '2.4vw',
                            marginTop: '-2vw'
                        }}>
                            <a style={{
                                margin: '1.5vw'
                            }} href='/en'>
                                <img src={gb} height='18vw'/>
                            </a>
                            <a style={{
                                margin: '1.5vw'
                            }} href='/tr'>
                                <img src={tr} height='18vw'/>
                            </a>
                        </div>
                    </div>
                </div>

            )
        }
    }

    const feedbackFormModal = () => {
        if(feedbackForm === true) {
            return (
                <div data-aos="fade" style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div onClick={handleFeedbackClose} style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute'
                    }}>

                    </div>
                    <div data-aos="fade-up" style={{
                        width: '30vw',
                        height: '24vw',
                        background: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        zIndex: '999999999'
                    }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2.4vw',
                            marginTop: '1vw'
                        }}>
                            Зворонтній зв'язок
                        </span>
                        <form style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} ref={form} onSubmit={sendEmail}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                marginTop: '1.5vw'
                            }}>
                                <label style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    color: 'white',
                                    fontWeight: '600',
                                    fontSize: '1.1vw'
                                }}>Ваше ім'я</label>
                                <input required style={{
                                    width: '20vw',
                                    height: '2.5vw',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '600',
                                    fontSize: '1.4vw'
                                }} type="text" name="user_name" />
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                marginTop: '1vw'
                            }}>
                                <label style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    color: 'white',
                                    fontWeight: '600',
                                    fontSize: '1.1vw'
                                }}>Ваша пошта</label>
                                <input required style={{
                                    width: '20vw',
                                    height: '2.5vw',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '600',
                                    fontSize: '1.4vw'
                                }} type="email" name="user_email" />
                            </div>
                            <input type="submit" value='Надіслати' shape='round' style={{
                                fontFamily: 'Russo One, Sans-serif',
                                fontWeight: '550',
                                height: '2.5vw',
                                width: '10vw',
                                background: 'white',
                                backgroundSize: 'cover',
                                color: 'black',
                                border: 'none',
                                marginTop: '3vw',
                                fontSize: '1.1vw',
                                borderRadius: '3vw'

                            }}/>
                        </form>
                    </div>
                </div>

            )
        }
    }

    const feedbackFormModalMobile = () => {
        if(feedbackFormMobile === true) {
            return (
                <div data-aos="fade" style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div onClick={handleFeedbackMobileClose} style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'absolute'
                    }}>

                    </div>
                    <div data-aos="fade-up" style={{
                        width: '95vw',
                        height: '75vh',
                        background: 'black',
                        border: 'solid 3px #C0C0C0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        zIndex: '999999999'
                    }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2em',
                            marginTop: '1em'
                        }}>
                            Зворонтній зв'язок
                        </span>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '1.2em',
                            textAlign: 'center',
                            marginTop: '0.3em'
                        }}>
                            Наш менеджер залюбки зв'яжеться з вами
                        </span>
                        <form style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            marginTop: '0.5em'
                        }} ref={form} onSubmit={sendEmail}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                marginTop: '1.5vw',
                                width: '85%'
                            }}>
                                <label style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    color: 'white',
                                    fontWeight: '600',
                                    fontSize: '1.1em'
                                }}>Ваше ім'я</label>
                                <input required style={{
                                    width: '100%',
                                    height: '2em',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '600',
                                    fontSize: '1.5em'
                                }} type="text" name="user_name" />
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                marginTop: '1vw',
                                width: '85%'
                            }}>
                                <label style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    color: 'white',
                                    fontWeight: '600',
                                    fontSize: '1.1em'
                                }}>Ваша пошта</label>
                                <input required style={{
                                    width: '100%',
                                    height: '2em',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '600',
                                    fontSize: '1.5em'
                                }} type="email" name="user_email" />
                            </div>
                            <div style={{
                                height: '100%'
                            }}>

                            </div>
                            <input type="submit" value='Надіслати' shape='round' style={{
                                fontFamily: 'Russo One, Sans-serif',
                                fontWeight: '550',
                                height: '4em',
                                width: '10em',
                                background: 'white',
                                backgroundSize: 'cover',
                                color: 'black',
                                border: 'none',
                                fontSize: '1.2em',
                                borderRadius: '3em',
                                marginBottom: '1.5em'

                            }}/>
                        </form>
                    </div>
                </div>

            )
        }
    }

    const feedbackDoneModal = () => {
        if(feedbackDone === true) {
            return (
                <div data-aos="fade-up" style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: '9999',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{
                        width: '33vw',
                        height: '15vw',
                        background: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2.4vw',
                            marginTop: '1vw'
                        }}>
                            Дякуємо за звернення
                        </span>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '1.6vw',
                            margin: '0.5vw'
                        }}>
                            Заявка на опрацюванні
                        </span>
                        <Button onClick={handleFeedbackClose} type="submit"shape='round' style={{
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            height: '2.5vw',
                            width: '10vw',
                            background: 'white',
                            backgroundSize: 'cover',
                            color: 'black',
                            border: 'none',
                            margin: '2vw',
                            fontSize: '1.1vw',
                        }}>Закрити</Button>
                    </div>
                </div>

            )
        }
    }

    const mobileMenu = () => {
        if(menu === true) {
            return (
                <>
                    <div data-aos="fade-right" style={{
                        background: 'black',
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        zIndex: '99',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start'
                    }}>
                        <a onClick={() => {
                            setMenu(false)
                        }} style={{
                            textDecoration: 'none',
                            marginTop: '10vh'
                        }} href='#audit'>
                        <span className='label-hover' type="text" style={{
                            fontSize: '3vh',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            marginLeft: '1.4em',
                            marginRight: '1.4em',
                            color: 'white'
                        }}>Системний аудит</span>
                        </a>

                        <a onClick={() => {
                            setMenu(false)
                        }} style={{
                            textDecoration: 'none',
                            marginTop: '2vh'
                        }} href='#forecast'>
                        <span className='label-hover' type="text" style={{
                            fontSize: '3vh',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            marginLeft: '1.4em',
                            marginRight: '1.4em',
                            color: 'white'
                        }}>Прогнозування розвитку</span>
                        </a>

                        <a onClick={() => {
                            setMenu(false)
                        }} style={{
                            textDecoration: 'none',
                            marginTop: '2vh'
                        }} href='#startups'>
                        <span className='label-hover' type="text" style={{
                            fontSize: '3vh',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            marginLeft: '1.4em',
                            marginRight: '1.4em',
                            color: 'white'
                        }}>Підтрмка стартапів</span>
                        </a>

                        <a onClick={() => {
                            setMenu(false)
                        }} style={{
                            textDecoration: 'none',
                            marginTop: '2vh'
                        }} href='#partnership'>
                        <span className='label-hover' type="text" style={{
                            fontSize: '3vh',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            marginLeft: '1.4em',
                            marginRight: '1.4em',
                            color: 'white'
                        }}>Партнерство</span>
                        </a>

                        <a onClick={() => {
                            setMenu(false)
                        }} style={{
                            textDecoration: 'none',
                            marginTop: '2vh'
                        }} href='#contacts'>
                        <span className='label-hover' type="text" style={{
                            fontSize: '3vh',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            marginLeft: '1.4em',
                            marginRight: '1.4em',
                            color: 'white'
                        }}>контакти</span>
                        </a>

                    </div>
                </>
            )
        }
    }

    const menuSelector = () => {
        if(menu === false){
            return(
                <img data-aos="fade" onClick={() => {
                    setMenu(true)
                }} src={menuImg} height='25vw' />
            )
        } else if(menu === true){
            return(
                <img data-aos="fade" onClick={() => {
                    setMenu(false)
                }} src={closeImg} height='25vw' />
            )
        }
    }



    if (window.navigator.userAgent.toLowerCase().includes('firefox')) {
        return(
            <div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'black',
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: '0',
                    zIndex: '9999'
                }}>
                    <span style={{
                        fontSize: '7em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.4em',
                        color: 'white',
                        marginTop: '-1.5em'
                    }}>AKSON</span><br /><br />
                    <span style={{
                        fontSize: '3em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.1em',
                        color: 'white'
                    }}>You are using an unsupported browser</span><br />
                    <span style={{
                        fontSize: '2em',
                        fontFamily: 'monospace',
                        fontWeight: '600',
                        letterSpacing: '0.1em',
                        color: 'white'
                    }}>We would recommend you to use another browser such as Chrome or Safari</span>
                </div>
            </div>
        )
    } else {
        if(device === 'desktop') {  //DESKTOP
            return (
                <>{fetchPreloader()}
                    {languageSelectorModal()}
                    {feedbackFormModal()}
                    {feedbackDoneModal()}
                    <div style={{
                        height: '4.5vh',
                        width: '100%',
                        backgroundColor: 'black',
                        zIndex: '999',
                    }}/>
                    <div style={{
                        height: '4.5vh',
                        position: 'fixed',
                        width: '100%',
                        backgroundColor: 'rgba(22, 22, 23, .8)',
                        zIndex: '1000',
                        marginTop: '-4.5vh',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Flex style={{
                            width: '100%',
                            marginTop: '-0.18vw'
                        }} justify="center" align="center">
                            <a href='#'>
                        <span style={{
                            fontSize: '1vw',
                            fontFamily: 'monospace',
                            fontWeight: '200',
                            letterSpacing: '0.4em',
                            paddingLeft: '0.4em',
                            paddingTop: '0.05em',
                            color: 'white'
                        }}>Akson</span>
                            </a>
                            <a href='#audit'>
                                <Button className='label-hover' type="text" size="large" style={{
                                    fontSize: '0.75vw',
                                    fontWeight: '500',
                                    marginLeft: '1.4em',
                                    marginRight: '1.4em',
                                    color: 'white'
                                }}>Системний аудит</Button>
                            </a>
                            <a href='#forecast'>
                                <Button className='label-hover' type="text" size="large" style={{
                                    fontSize: '0.75vw',
                                    fontWeight: '500',
                                    marginLeft: '1.4em',
                                    marginRight: '1.4em',
                                    color: 'white'
                                }}>Прогнозування розвитку</Button>
                            </a>
                            <a href='#startups'>
                                <Button className='label-hover' type="text" size="large" style={{
                                    fontSize: '0.75vw',
                                    fontWeight: '500',
                                    marginLeft: '1.4em',
                                    marginRight: '1.4em',
                                    color: 'white'
                                }}>Підтримка стартапів</Button>
                            </a>
                            <a href='#partnership'>
                                <Button className='label-hover' type="text" size="large" style={{
                                    fontSize: '0.75vw',
                                    fontWeight: '500',
                                    marginLeft: '1.4em',
                                    marginRight: '1.4em',
                                    color: 'white'
                                }}>Партнерство</Button>
                            </a>
                            {/*<Button className='label-hover' type="text" size="large" style={{*/}
                            {/*    fontSize: '0.75vw',*/}
                            {/*    fontWeight: '500',*/}
                            {/*    marginLeft: '1.4em',*/}
                            {/*    marginRight: '1.4em',*/}
                            {/*    color: 'white'*/}
                            {/*}}>Стати спонсором</Button>*/}
                            <a href='#contacts'>
                                <Button className='label-hover' type="text" size="large" style={{
                                    fontSize: '0.75vw',
                                    fontWeight: '500',
                                    marginLeft: '1.4em',
                                    marginRight: '1.4em',
                                    color: 'white'
                                }}>Контакти</Button>
                            </a>
                            <a>
                                <img onClick={handleLanguage} height='18vw' style={{
                                    marginLeft: '0.5em'
                                }} src={ua}/>
                            </a>
                        </Flex>
                    </div>

                    <Carousel style={{
                        zIndex: '3',
                        marginBottom: '5vw'
                    }}
                              className="carousel-container" autoplay autoplaySpeed={3000}>
                        <div>
                            <div style={{
                                width: '100%',
                                height: '77vh',
                                backgroundImage: 'url(' + img10 +')',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vw',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>Інноваційні рішення</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '77vh',
                                backgroundImage: 'url(https://cdn11.bigcommerce.com/s-bfxxgrup/images/stencil/1000x1000/uploaded_images/server-technician.jpg?t=1661350163)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vw',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>Автоматизація процесів</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '77vh',
                                backgroundImage: 'url(' + img11 + ')',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vw',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>Використання штучного ітелекту</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '77vh',
                                backgroundImage: 'url(https://assets.techrepublic.com/uploads/2022/10/tr-shouldnt-virtualize.jpeg)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vw',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>Хмарні технології</h1>
                            </div>

                        </div>
                    </Carousel>
                    <div id="audit" style={{
                        position: 'absolute',
                        marginTop: '-25vh'
                    }}/>
                    <div style={{
                        height: '77.7vh',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Fade>
                            {cards()}


                        </Fade>
                    </div>






                    <Flex className='timeline-animation9' justify='center' style={{
                        height: '18vw',
                        flexDirection: 'row'
                    }}>
                        {/*<div id='timeline-animation9' style={{*/}
                        {/*    position: 'absolute',*/}
                        {/*    background: 'url(https://img.freepik.com/free-vector/digital-dark-wavy-background_23-2148388254.jpg?t=st=1725962103~exp=1725965703~hmac=28ff5dafcc436557919fe955094dbbe48e95c7559d98940560432de67f443671&w=2000)',*/}
                        {/*    backgroundSize: 'cover',*/}
                        {/*    width: '100vw',*/}
                        {/*    height: '102vh',*/}
                        {/*    marginTop: '-7.5vh'*/}
                        {/*}}>*/}

                        {/*</div>*/}


                        <div style={{
                            position: 'absolute',
                            width: '24vw',
                            height: '24vw',
                            left: '0',
                            marginTop: '-4em',
                            zIndex: '10',
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='card2'>
                    <span style={{
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'white',
                        fontWeight: '600',
                        fontSize: '1.1vw',
                        width: '75%',
                        zIndex: '11'
                    }}>
                            1. Огляд документації, політик, процедур і звітів.<br/><br/>
                            2. Інтерв'ю з ключовими працівниками, відповідальними за інформаційні системи.<br/><br/>
                            3. Спостереження за роботою інформаційних систем і технологій.
                        </span>
                        </div>

                        <div style={{
                            position: 'absolute',
                            // background: 'url(https://t4.ftcdn.net/jpg/01/87/30/09/360_F_187300935_mdN8cNcLmj6poUvnS1WMmEeFa34z1ybd.jpg)',
                            background: 'black',
                            backgroundSize: 'cover',
                            width: '47vw',
                            height: '47.5vh',
                            right: '51.5vw',
                            marginTop: '-4em'
                        }}>

                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'linear-gradient(135deg, #000000, #434343)',
                            width: '24vw',
                            height: '24vw',
                            left: '0',
                            marginTop: '-4em',
                            opacity: '0.8'
                        }}>

                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'transparent',
                            width: '24vw',
                            height: '24vw',
                            left: '0',
                            marginTop: '-4em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2.5em',
                        }}>
                            ЗБІР ІНФОРМАЦІЇ
                        </span>


                        </div>


                        <div style={{
                            position: 'absolute',
                            width: '24vw',
                            height: '24vw',
                            left: '25vw',
                            marginTop: '-4em',
                            zIndex: '10',
                            background: '#eef2f3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='card2'>
                    <span className='card2-text' style={{
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'black',
                        fontWeight: '600',
                        fontSize: '1.1vw',
                        width: '75%',
                        zIndex: '11'
                    }}>
                            1. Оцінка зібраної інформації на відповідність стандартам і вимогам.<br/><br/>
                            2. Виявлення недоліків, вразливостей і ризиків.<br/><br/>
                            3. Аналіз ефективності заходів безпеки та управління.
                        </span>
                        </div>
                        <div style={{
                            position: 'absolute',
                            // background: 'url(https://business.cornell.edu/wp-content/uploads/sites/2/2019/02/execed-data-analytics.jpg)',
                            background: 'white',
                            backgroundSize: 'cover',
                            width: '24vw',
                            height: '24vw',
                            left: '25vw',
                            marginTop: '-4em'
                        }}>
                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'linear-gradient(to right, #8e9eab, #eef2f3)',
                            width: '24vw',
                            height: '24vw',
                            left: '25vw',
                            marginTop: '-4em',
                            opacity: '0.5'
                        }}>

                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'transparent',
                            width: '24vw',
                            height: '24vw',
                            left: '25vw',
                            marginTop: '-4em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: '#343a40',
                            fontWeight: '600',
                            fontSize: '2.5em',
                        }}>
                            АНАЛІЗ І ОЦІНКА
                        </span>


                        </div>


                        <div style={{
                            position: 'absolute',
                            width: '24vw',
                            height: '24vw',
                            left: '75vw',
                            marginTop: '-4em',
                            zIndex: '10',
                            background: '#eef2f3',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='card2'>
                    <span className='card2-text' style={{
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'black',
                        fontWeight: '600',
                        fontSize: '1.1vw',
                        width: '75%',
                        zIndex: '11'
                    }}>
                            1. Складання розгорнутого звіту про результати аудиту.<br/><br/>
                            2. Включення рекомендацій щодо покращення безпеки, ефективності та відповідності.
                        </span>
                        </div>
                        <div style={{
                            position: 'absolute',
                            // background: 'url("https://images.pexels.com/photos/5387246/pexels-photo-5387246.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
                            background: 'white',
                            backgroundSize: 'cover',
                            width: '24vw',
                            height: '24vw',
                            left: '75vw',
                            marginTop: '-4em',
                        }}>
                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'linear-gradient(to right, #8e9eab, #eef2f3)',
                            width: '24vw',
                            height: '24vw',
                            left: '75vw',
                            marginTop: '-4em',
                            opacity: '0.5'
                        }}>

                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'transparent',
                            width: '24vw',
                            height: '24vw',
                            left: '75vw',
                            marginTop: '-4em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: '#343a40',
                            fontWeight: '600',
                            fontSize: '2.5em',
                        }}>
                            ПІДГОТОВКА ЗВІТУ
                        </span>


                        </div>


                        <div style={{
                            position: 'absolute',
                            width: '24vw',
                            height: '24vw',
                            left: '50vw',
                            marginTop: '-4em',
                            zIndex: '10',
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} className='card2'>
                    <span style={{
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'white',
                        fontWeight: '600',
                        fontSize: '1.1vw',
                        width: '75%',
                        zIndex: '11'
                    }}>
                            1. Впровадження рекомендацій для усунення недоліків і вразливостей.<br/><br/>
                            2. Регулярний моніторинг і повторні аудити для оцінки прогресу.
                        </span>
                        </div>
                        <div style={{
                            position: 'absolute',
                            // background: 'url(https://images.computerwoche.de/bdb/3357616/765x431.jpg)',
                            background: 'black',
                            backgroundSize: 'cover',
                            width: '24vw',
                            height: '24vw',
                            left: '50vw',
                            marginTop: '-4em',
                        }}>
                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'linear-gradient(-135deg, #000000, #434343)',
                            width: '24vw',
                            height: '24vw',
                            left: '50vw',
                            marginTop: '-4em',
                            opacity: '0.8'
                        }}>

                        </div>
                        <div style={{
                            position: 'absolute',
                            background: 'transparent',
                            width: '24vw',
                            height: '24vw',
                            left: '50vw',
                            marginTop: '-4em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <span style={{
                            fontFamily: 'Russo One, Sans-serif',
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '2.5em',
                            width: '90%',
                            textAlign: 'center'
                        }}>
                            ВИКОНАННЯ РЕКОМЕНДАЦІЙ І МОНІТОРИНГ
                        </span>


                        </div>

                    </Flex>


                    <div id="forecast" style={{height: '0.1px'}}/>
                    <div style={{
                        width: '100%',
                        height: '75vw',
                        marginTop: '3.7vh',
                        // background: 'url(https://img.freepik.com/free-vector/abstract-background-with-flowing-waves_1048-6527.jpg?t=st=1725961607~exp=1725965207~hmac=57acd0f39f79f33e78ee92f9613d6d1fd553475c53dfbbe0210fa1b57700c00a&w=1800)',
                        // backgroundSize: 'cover'
                    }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '100%',
                        height: '75vh'
                    }}>
                        <div data-aos="fade-right" data-aos-anchor-placement="top-center" style={{
                            width: '40vw',
                            height: '50vh',
                            background: 'url(' + img13 + ')',
                            borderRadius: '20px',
                            marginTop: '7em',
                            marginRight: '-8em'
                        }}>

                        </div>
                        <div data-aos="fade-up" data-aos-anchor-placement="top-center" style={{
                            width: '45vw',
                            height: '50vh',
                            background: 'linear-gradient(to right, #edf1f5, #eef2f3)',
                            backgroundSize: 'cover',
                            borderRadius: '20px',
                            marginTop: '5em',
                            padding: '2.5em',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            zIndex: '2',
                            marginLeft: '-8em'
                        }}>
                        <span style={{
                            fontSize: '1.8vw',
                            textAlign: 'center',
                            width: '100%',
                            fontFamily: 'Russo One, Sans-serif',
                            textTransform: 'uppercase',
                            fontWeight: '800',
                            color: 'black'
                        }}>Прогнозування розвитку бізнесу</span><br/>
                            <span style={{
                                fontSize: '1.4vw',
                                textAlign: 'left',
                                width: '90%',
                                fontFamily: 'Russo One, Sans-serif',
                                fontWeight: '800',
                                color: 'black'
                            }}>
                            Аналіз та прогнозування розвитку бізнесу є критично важливими для успішного стратегічного управління.<br/><div
                                style={{height: '0.7em'}}/>
Вони дозволяють організаціям адаптуватися до змін у зовнішньому середовищі, використовувати можливості для зростання та мінімізувати ризики, що сприяє досягненню довгострокових цілей та сталому розвитку.
                        </span>
                        </div>
                    </div>


                    <Flex justify='center' style={{
                        flexDirection: 'row'
                    }}>
                        <Flex data-aos="fade-right" style={{
                            width: '30%',
                            height: 'auto',
                            marginTop: '-7.4em',
                            marginRight: '3em',
                            fontFamily: 'Russo One, Sans-serif',
                            alignItems: 'start'
                        }} justify="center" align="center">
                    <span style={{
                        width: '100%',
                        fontSize: '1.6em',
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'rgb(211, 211, 211)',
                    }}>

                        <div style={{
                            height: '0.3em'
                        }}/>
                        <span style={{
                            fontSize: '1.4em',
                            fontWeight: '550'
                        }
                        }><span style={{paddingBottom: '0.05em'}}>Розробка стратегій</span></span><br/>
                        <div style={{height: '0.5em'}}/>
                        <span style={{fontWeight: '550'}}>Визначення</span> стратегічних цілей на основі аналізу та прогнозування.<br/>
                        <span style={{fontWeight: '550'}}>Розробка</span> довгострокових і короткострокових стратегій для досягнення поставлених цілей.<br/><br/>

                    </span>
                        </Flex>
                        <Flex data-aos="fade-left" style={{
                            width: '30%',
                            height: 'auto',
                            marginLeft: '3em',
                            marginTop: '-7em',
                            fontFamily: 'Russo One, Sans-serif',
                            alignItems: 'start'
                        }} justify="center" align="center">
                    <span style={{
                        width: '100%',
                        fontSize: '1.6em',
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'rgb(211, 211, 211)',
                    }}>

    <span style={{
        fontSize: '1.3em',
        fontWeight: '550'
    }
    }><span style={{paddingBottom: '0.05em'}}>Планування ресурсів</span> </span><br/>
                        <div style={{height: '0.5em'}}/>
                        <span style={{fontWeight: '550'}}>Визначення</span> необхідних ресурсів для реалізації стратегій.<br/>
                        <span style={{fontWeight: '550'}}>Розподіл</span> ресурсів.<br/><br/>

                    </span>
                        </Flex>
                    </Flex>

                    <div style={{height: '8em'}}/>

                    <Flex justify='center' style={{
                        flexDirection: 'row',
                    }}>
                        <Flex data-aos="fade-right" style={{
                            width: '30%',
                            height: 'auto',
                            marginTop: '-7.4em',
                            marginRight: '3em',
                            fontFamily: 'Russo One, Sans-serif',
                            alignItems: 'start'
                        }} justify="center" align="center">
                    <span style={{
                        width: '100%',
                        fontSize: '1.6em',
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'rgb(211, 211, 211)',
                    }}>

                        <div style={{
                            height: '0.3em'
                        }}/>
                        <span style={{
                            fontSize: '1.3em',
                            fontWeight: '550'
                        }
                        }><span style={{paddingBottom: '0.05em'}}>Моніторинг і контроль</span></span><br/>
                        <div style={{height: '0.5em'}}/>
                        <span
                            style={{fontWeight: '550'}}>Регулярний моніторинг</span> виконання стратегій та планів.<br/>
                        <span style={{fontWeight: '550'}}>Внесення</span> корективів на основі змін у внутрішньому та зовнішньому середовищі.<br/><br/>
                    </span>
                        </Flex>
                        <Flex data-aos="fade-left" style={{
                            width: '30%',
                            height: 'auto',
                            marginLeft: '3em',
                            marginTop: '-7em',
                            fontFamily: 'Russo One, Sans-serif',
                            alignItems: 'start'
                        }} justify="center" align="center">
                    <span style={{
                        width: '100%',
                        fontSize: '1.6em',
                        fontFamily: 'Russo One, Sans-serif',
                        color: 'rgb(211, 211, 211)',
                    }}>

    <span style={{
        fontSize: '1.3em',
        fontWeight: '550'
    }
    }><span style={{paddingBottom: '0.05em'}}>Оцінка ризиків</span></span><br/>
                        <div style={{height: '0.5em'}}/>
                        <span style={{fontWeight: '550'}}>Визначення</span> потенційних ризиків і розробка планів щодо їх мінімізації.<br/>
                        <span style={{fontWeight: '550'}}>Використання</span> методів ризик-менеджменту для забезпечення стабільного розвитку.<br/><br/>
                    </span>
                        </Flex>
                    </Flex>
                    <br/>
                    <Flex style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <AttentionSeeker effect="pulse">
                            <Button onClick={handleFeedback} shape='round' type='' style={{
                                fontFamily: 'Russo One, Sans-serif',
                                fontWeight: '550',
                                fontSize: '1.4em',
                                height: '3.2em',
                                width: '14em',
                                marginTop: '1em',
                                background: 'white',
                                backgroundSize: 'cover',
                                color: 'black',
                                border: 'none'

                            }}><span style={{}}>Замовити прогнозування</span></Button>
                        </AttentionSeeker>
                    </Flex>

                    </div>



                    <div id='startups' style={{
                        width: '100vw',
                        height: '60vh',
                        background: 'black',
                        backgroundSize: 'cover',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // position: 'sticky',
                        // top: '0px'
                    }}>
                        <img src='https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-colorful-abstract-lines-on-black-background-free-photo.jpg?w=600&quality=80' style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            filter: 'brightness(30%)'
                        }}/>
                        <span style={{
                            color: 'white',
                            fontFamily: 'Russo One, Sans-serif',
                            fontWeight: '550',
                            fontSize: '3vw',
                            position: 'absolute'
                        }}>
                            Підтримка студентських стартапів
                        </span>
                    </div>

                    {/*<div style={{*/}
                    {/*    height: '20vh'*/}
                    {/*}}>*/}

                    {/*</div>*/}

                    <div style={{
                        width: '100%'
                    }}>
                        <div style={{
                            width: '100vw',
                            height: '40vh',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://www.shutterstock.com/image-photo/group-college-students-performing-experiment-600nw-1892185093.jpg)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.3vw'
                                        }}>
                                        Лабораторії, обладнання та технічна підтримка університетів
                                    </span>
                                    </div>
                                </div>
                                <div style={{
                                    width: '60%'
                                }}>

                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://latinamericanleadershipacademy.org/wp-content/uploads/2023/05/Why-is-mentorship-important-and-how-to-find-a-mentor-for-you-1024x737.png)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.3vw'
                                        }}>
                                        Професійна менторська підтримка
                                    </span>
                                    </div>
                                </div>
                                <div style={{
                                    width: '60%'
                                }}>

                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://archistar.ai/wp-content/uploads/2021/01/iStock-1206974609.jpg)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.5vw'
                                        }}>
                                        Розробка бізнес-планів та стратегії
                                    </span>
                                    </div>
                                </div>
                                <div style={{
                                    width: '60%'
                                }}>

                                </div>
                            </div>
                        </div>

                        <div style={{
                            width: '100vw',
                            height: '40vh',
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://trafft.com/wp-content/uploads/2021/04/consult-header-768x512.jpg)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.3vw'
                                        }}>

                                            Консультації експертів з різних галузей
                                    </span>
                                    </div>
                                </div>
                                <div style={{
                                    width: '60%'
                                }}>

                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://business.rice.edu/sites/default/files/styles/16_9_1600x900/public/2023-10/WILC-2024-1.jpg?h=a1e1a043&itok=C3wPr5V6)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.3vw'
                                        }}>
                                        Участь у конференціях, семінарах та конкурсах
                                    </span>
                                    </div>
                                </div>
                                <div style={{
                                    width: '60%'
                                }}>

                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '0.5vw',
                                background: 'url(https://supportgroups.org.au/wp-content/uploads/2021/08/social-scaled.jpg)',
                                backgroundSize: 'cover'
                            }}>
                                <div style={{
                                    width: '40%'
                                }}>
                                    <div style={{
                                        background: 'rgba(0, 0, 0, 0.35)',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}>
                                        <span style={{
                                            color: 'white',
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            fontSize: '1.5vw',
                                            margin: '0.5vw'
                                        }}>
                                        Фінансування та підтримка
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        width: '100vw',
                        height: '102vh',
                        background: 'black',
                        backgroundSize: 'cover',
                        marginTop: '-20vh'
                    }}>
                        {/*<img src='https://img.freepik.com/free-photo/abstract-wave-cyan-color-black-background_1268-28760.jpg?t=st=1725908341~exp=1725911941~hmac=e7c14a2be664bc65f43978c21dc950d3d1a20b58380c4404afcace2d6b14436d&w=2000' style={{*/}
                        {/*    width: '100%',*/}
                        {/*    objectFit: 'cover',*/}
                        {/*    filter: 'brightness(60%)',*/}
                        {/*    position: 'absolute',*/}
                        {/*    marginTop: '355vh'*/}
                        {/*}}/>*/}
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        {/*    <span style={{*/}
                        {/*        color: 'white',*/}
                        {/*        fontFamily: 'Russo One, Sans-serif',*/}
                        {/*        fontWeight: '550',*/}
                        {/*        fontSize: '2.5vw',*/}
                        {/*        position: 'absolute'*/}
                        {/*    }}>*/}
                        {/*    Ми пропонуємо:*/}
                        {/*</span>*/}
                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '20vh'
                        }}>
                           {/*<div style={{*/}
                           {/*    display: 'flex',*/}
                           {/*    flexDirection: 'row'*/}
                           {/*}}>*/}
                           {/*    <div style={{*/}
                           {/*        height: '57.5vh',*/}
                           {/*        width: '50vw',*/}
                           {/*        background: 'url(https://www.shutterstock.com/image-photo/group-college-students-performing-experiment-600nw-1892185093.jpg)',*/}
                           {/*        backgroundSize: 'cover',*/}
                           {/*        zIndex: '1',*/}
                           {/*        display: 'flex',*/}
                           {/*        justifyContent: 'center',*/}
                           {/*        alignItems: 'center',*/}
                           {/*        textAlign: 'center',*/}
                           {/*        margin: '1vh'*/}
                           {/*    }}>*/}
                           {/*        <div style={{*/}
                           {/*            position: 'absolute',*/}
                           {/*            height: '57.5vh',*/}
                           {/*            width: '50vw',*/}
                           {/*            backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*        }}>*/}

                           {/*        </div>*/}
                           {/*        <span style={{*/}
                           {/*            color: 'white',*/}
                           {/*            fontFamily: 'Russo One, Sans-serif',*/}
                           {/*            fontWeight: '550',*/}
                           {/*            fontSize: '1.75vw',*/}
                           {/*            zIndex: '2'*/}
                           {/*        }}>*/}
                           {/*         Лабораторії, обладнання та технічна підтримка університетів*/}
                           {/*     </span>*/}
                           {/*    </div>*/}
                           {/*    <div style={{*/}
                           {/*        height: '57.5vh',*/}
                           {/*        width: '50vw',*/}
                           {/*        background: 'url(https://latinamericanleadershipacademy.org/wp-content/uploads/2023/05/Why-is-mentorship-important-and-how-to-find-a-mentor-for-you-1024x737.png)',*/}
                           {/*        backgroundSize: 'cover',*/}
                           {/*        zIndex: '1',*/}
                           {/*        display: 'flex',*/}
                           {/*        justifyContent: 'center',*/}
                           {/*        alignItems: 'center',*/}
                           {/*        textAlign: 'center',*/}
                           {/*        margin: '1vh'*/}
                           {/*    }}>*/}
                           {/*        <div style={{*/}
                           {/*            position: 'absolute',*/}
                           {/*            height: '57.5vh',*/}
                           {/*            width: '50vw',*/}
                           {/*            backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*        }}>*/}

                           {/*        </div>*/}
                           {/*        <span style={{*/}
                           {/*            color: 'white',*/}
                           {/*            fontFamily: 'Russo One, Sans-serif',*/}
                           {/*            fontWeight: '550',*/}
                           {/*            fontSize: '1.75vw',*/}
                           {/*            zIndex: '2'*/}
                           {/*        }}>*/}
                           {/*         Професійна менторська підтримка*/}
                           {/*     </span>*/}
                           {/*    </div>*/}
                           {/*</div>*/}

                           {/* <div style={{*/}
                           {/*     display: 'flex',*/}
                           {/*     flexDirection: 'row'*/}
                           {/* }}>*/}
                           {/* <div style={{*/}
                           {/*     height: '57.5vh',*/}
                           {/*     width: '50vw',*/}
                           {/*     background: 'url(https://archistar.ai/wp-content/uploads/2021/01/iStock-1206974609.jpg)',*/}
                           {/*     backgroundSize: 'cover',*/}
                           {/*     zIndex: '1',*/}
                           {/*     display: 'flex',*/}
                           {/*     justifyContent: 'center',*/}
                           {/*     alignItems: 'center',*/}
                           {/*     textAlign: 'center',*/}
                           {/*     margin: '1vh'*/}
                           {/* }}>*/}
                           {/*     <div style={{*/}
                           {/*         position: 'absolute',*/}
                           {/*         height: '57.5vh',*/}
                           {/*         width: '50vw',*/}
                           {/*         backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*     }}>*/}

                           {/*     </div>*/}
                           {/*     <span style={{*/}
                           {/*         color: 'white',*/}
                           {/*         fontFamily: 'Russo One, Sans-serif',*/}
                           {/*         fontWeight: '550',*/}
                           {/*         fontSize: '1.75vw',*/}
                           {/*         zIndex: '2'*/}
                           {/*     }}>*/}
                           {/*         Розробка бізнес-планів та стратегії*/}
                           {/*     </span>*/}
                           {/* </div>*/}
                           {/* <div style={{*/}
                           {/*     height: '57.5vh',*/}
                           {/*     width: '50vw',*/}
                           {/*     background: 'url(https://trafft.com/wp-content/uploads/2021/04/consult-header-768x512.jpg)',*/}
                           {/*     backgroundSize: 'cover',*/}
                           {/*     zIndex: '1',*/}
                           {/*     display: 'flex',*/}
                           {/*     justifyContent: 'center',*/}
                           {/*     alignItems: 'center',*/}
                           {/*     textAlign: 'center',*/}
                           {/*     margin: '1vh'*/}
                           {/* }}>*/}
                           {/*     <div style={{*/}
                           {/*         position: 'absolute',*/}
                           {/*         height: '57.5vh',*/}
                           {/*         width: '50vw',*/}
                           {/*         backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*     }}>*/}

                           {/*     </div>*/}
                           {/*     <span style={{*/}
                           {/*         color: 'white',*/}
                           {/*         fontFamily: 'Russo One, Sans-serif',*/}
                           {/*         fontWeight: '550',*/}
                           {/*         fontSize: '1.75vw',*/}
                           {/*         zIndex: '2'*/}
                           {/*     }}>*/}
                           {/*         Консультації експертів з різних галузей*/}
                           {/*     </span>*/}
                           {/* </div>*/}
                           {/* </div>*/}

                           {/* <div style={{*/}
                           {/*     display: 'flex',*/}
                           {/*     flexDirection: 'row'*/}
                           {/* }}>*/}
                           {/* <div style={{*/}
                           {/*     height: '57.5vh',*/}
                           {/*     width: '50vw',*/}
                           {/*     background: 'url(https://business.rice.edu/sites/default/files/styles/16_9_1600x900/public/2023-10/WILC-2024-1.jpg?h=a1e1a043&itok=C3wPr5V6)',*/}
                           {/*     backgroundSize: 'cover',*/}
                           {/*     zIndex: '1',*/}
                           {/*     display: 'flex',*/}
                           {/*     justifyContent: 'center',*/}
                           {/*     alignItems: 'center',*/}
                           {/*     textAlign: 'center',*/}
                           {/*     margin: '1vh'*/}
                           {/* }}>*/}
                           {/*     <div style={{*/}
                           {/*         position: 'absolute',*/}
                           {/*         height: '57.5vh',*/}
                           {/*         width: '50vw',*/}
                           {/*         backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*     }}>*/}

                           {/*     </div>*/}
                           {/*     <span style={{*/}
                           {/*         color: 'white',*/}
                           {/*         fontFamily: 'Russo One, Sans-serif',*/}
                           {/*         fontWeight: '550',*/}
                           {/*         fontSize: '1.75vw',*/}
                           {/*         zIndex: '2'*/}
                           {/*     }}>*/}
                           {/*         Участь у конференціях, семінарах та конкурсах*/}
                           {/*     </span>*/}
                           {/* </div>*/}
                           {/* <div style={{*/}
                           {/*     height: '57.5vh',*/}
                           {/*     width: '50vw',*/}
                           {/*     background: 'url(https://supportgroups.org.au/wp-content/uploads/2021/08/social-scaled.jpg)',*/}
                           {/*     backgroundSize: 'cover',*/}
                           {/*     zIndex: '1',*/}
                           {/*     display: 'flex',*/}
                           {/*     justifyContent: 'center',*/}
                           {/*     alignItems: 'center',*/}
                           {/*     textAlign: 'center',*/}
                           {/*     margin: '1vh'*/}
                           {/* }}>*/}
                           {/*     <div style={{*/}
                           {/*         position: 'absolute',*/}
                           {/*         height: '57.5vh',*/}
                           {/*         width: '50vw',*/}
                           {/*         backgroundColor: 'rgba(0, 0, 0, 0.6)'*/}
                           {/*     }}>*/}

                           {/*     </div>*/}
                           {/*     <span style={{*/}
                           {/*         color: 'white',*/}
                           {/*         fontFamily: 'Russo One, Sans-serif',*/}
                           {/*         fontWeight: '550',*/}
                           {/*         fontSize: '1.75vw',*/}
                           {/*         zIndex: '2'*/}
                           {/*     }}>*/}
                           {/*         Фінансування та підтримка*/}
                           {/*     </span>*/}
                           {/* </div>*/}
                           {/* </div>*/}
                            <div style={{
                                height: '60vh',
                                width: '100vw',
                                background: 'url(https://www.miele.ua/media/misc_ua/media/assets_673_x/dealer/Partners_673_379.jpeg)',
                                backgroundSize: 'cover',
                                zIndex: '1',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                marginTop: '1vh'
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    height: '60vh',
                                    width: '100vw',
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)'
                                }}>

                                </div>
                                <span style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '550',
                                    fontSize: '2.5vw',
                                    zIndex: '2'
                                }}>
                                    Потужна партнерська програма
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id='partnership' style={{
                        position: 'absolute',
                        marginTop: '-85vh'
                    }}>

                    </div>
                    <div style={{
                        width: '100vw',
                        // background: 'url(https://img.freepik.com/free-vector/black-wave-gradient-modern-background_343694-2084.jpg?t=st=1725909048~exp=1725912648~hmac=9744d11d7e05c371df17d3b6838be8d39b8f8810974afcdc90b4f079ef62a7ba&w=2000)',
                        backgroundSize: 'cover',
                        marginTop: '-20vh',
                        zIndex: '3'
                    }}>
                        {/*<div style={{*/}
                        {/*    width: '100%',*/}
                        {/*    height: '2%',*/}
                        {/*    display: 'flex',*/}
                        {/*    justifyContent: 'center'*/}
                        {/*}}>*/}
                        {/*    <span style={{*/}
                        {/*        color: 'white',*/}
                        {/*        fontFamily: 'Russo One, Sans-serif',*/}
                        {/*        fontWeight: '550',*/}
                        {/*        fontSize: '4vw',*/}
                        {/*        position: 'absolute',*/}
                        {/*        marginTop: '-17vh'*/}
                        {/*    }}>*/}
                        {/*    Наші партнери:*/}
                        {/*</span>*/}
                        {/*</div>*/}

                        <div style={{
                            width: '100%',
                            height: '35%',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: 'black',
                            zIndex: '3',
                            justifyContent: 'center',
                        }}>
                            <div style={{
                                height: '23vw',
                                width: '23vw',
                                background: 'url(https://lh3.googleusercontent.com/p/AF1QipO06cNwET-TNMdIfnc4u1bJCVnMlowdLTl2rVbH=s1360-w1360-h1020-rw)',
                                backgroundSize: 'cover',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0.5vw',
                            }}>
                                {/*<div style={{*/}
                                {/*    position: 'absolute',*/}
                                {/*    height: '42vh',*/}
                                {/*    width: '50vw',*/}
                                {/*    backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                                {/*}}>*/}

                                {/*</div>*/}
                                <img src='https://nl-i-01.link4web.com/fasshane-logo.jpg' style={{
                                    width: '20vw',
                                    zIndex: '2'
                                }} />
                            </div>
                            <div style={{
                                height: '23vw',
                                width: '23vw',
                                background: 'url(https://studykoy.com/wp-content/uploads/2020/07/48131860942_3d8415d96a_b.jpg)',
                                backgroundSize: 'cover',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0.5vw'
                            }}>
                                {/*<div style={{*/}
                                {/*    position: 'absolute',*/}
                                {/*    height: '42vh',*/}
                                {/*    width: '50vw',*/}
                                {/*    backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                                {/*}}>*/}

                                {/*</div>*/}
                                <img src='https://www.sabanciuniv.edu/themes/custom/su/logo.svg' style={{
                                    width: '15vw',
                                    zIndex: '2'
                                }} />
                            </div>
                            <div style={{
                                height: '23vw',
                                width: '23vw',
                                background: 'url(https://lh4.googleusercontent.com/proxy/dROITRabSsFFuvLliPzvZ_RQTXUe8cMysaR1yXgYMt2NiIqgoNQT_VB2CK6k4GnGKrGTX-A6at5ufWqWB5Rp3I5m17re0BGv)',
                                backgroundSize: 'cover',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0.5vw'
                            }}>
                                {/*<div style={{*/}
                                {/*    position: 'absolute',*/}
                                {/*    height: '42vh',*/}
                                {/*    width: '50vw',*/}
                                {/*    backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                                {/*}}>*/}

                                {/*</div>*/}
                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ629M7clq4510rRSd-kICQbMyYk8HYxTG0bA&s' style={{
                                    width: '15vw',
                                    zIndex: '2'
                                }} />
                            </div>
                            <div style={{
                                height: '23vw',
                                width: '23vw',
                                background: 'url(https://kpi.ua/files/images-story/photo_2022-10-12%2018.58.03.jpeg)',
                                backgroundSize: 'cover',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '0.5vw'
                            }}>
                                {/*<div style={{*/}
                                {/*    position: 'absolute',*/}
                                {/*    height: '42vh',*/}
                                {/*    width: '50vw',*/}
                                {/*    backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                                {/*}}>*/}

                                {/*</div>*/}
                                <img src='https://upload.wikimedia.org/wikipedia/commons/4/4a/NTUU_KPI_logo.png' style={{
                                    width: '15vw',
                                    zIndex: '2'
                                }} />
                            </div>
                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <AttentionSeeker effect="pulse">
                                <Button onClick={handleFeedback} shape='round' type='' style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontWeight: '550',
                                    fontSize: '1.4em',
                                    height: '3.2em',
                                    width: '14em',
                                    marginTop: '1em',
                                    background: 'white',
                                    backgroundSize: 'cover',
                                    color: 'black',
                                    border: 'none'

                                }}><span style={{}}>Стати спонсором</span></Button>
                            </AttentionSeeker>
                        </div>



                        <div id='contacts' style={{
                            width: '100%',
                            height: '10vw',
                            backgroundColor: 'rgba(22, 22, 23, .8)',
                            marginTop: '2vw',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <div style={{
                                    width: '25%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <a style={{
                                        textDecoration: 'none',
                                        margin: '0.5vw'
                                    }} href='#'>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'monospace',
                                        fontWeight: '800',
                                        fontSize: '2vw',
                                        textDecoration: 'none',
                                        letterSpacing: '0.4em'
                                    }}>
                                        Akson
                                    </span>
                                    </a>
                                </div>
                                <div style={{
                                    width: '25%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.9vw',
                                        textDecoration: 'none',
                                        textAlign: 'left',
                                        marginBottom: '0.5vw'
                                    }}>
                                        ISTAMBUL
                                    </span>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.7vw',
                                        textDecoration: 'none',
                                        margin: '0.1vw',
                                        marginBottom: '0.3vw'
                                    }}>
                                        27 Walton Road, Kirby Le Soken,CO13 0DU
                                    </span>

                                    <a style={{
                                        textDecoration: 'none',
                                        margin: '0.1vw'
                                    }} href='mailto:manager@aksondev.com'>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.8vw',
                                        textDecoration: 'none'
                                    }}>
                                        manager@aksondev.com
                                    </span>
                                    </a>
                                    <a style={{
                                        textDecoration: 'none',
                                        margin: '0.1vw'
                                    }} href='tel:+900111234567'>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.8vw',
                                        textDecoration: 'none'
                                    }}>
                                        +90 011 123 45 67
                                    </span>
                                    </a>
                                </div>
                                <div style={{
                                    width: '25%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.9vw',
                                        textDecoration: 'none',
                                        textAlign: 'left',
                                        marginBottom: '0.5vw'
                                    }}>
                                        KYIV
                                    </span>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.7vw',
                                        textDecoration: 'none',
                                        margin: '0.1vw',
                                        marginBottom: '0.3vw'
                                    }}>
                                        Victoria Cottage, White Cross Lane, PE34 4SS
                                    </span>

                                    <a style={{
                                        textDecoration: 'none',
                                        margin: '0.1vw'
                                    }} href='mailto:manager@aksondev.com'>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.8vw',
                                        textDecoration: 'none'
                                    }}>
                                        manager@aksondev.com
                                    </span>
                                    </a>
                                    <a style={{
                                        textDecoration: 'none',
                                        margin: '0.1vw'
                                    }} href='tel:+380991234567'>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.8vw',
                                        textDecoration: 'none'
                                    }}>
                                        +38 099 123 45 67
                                    </span>
                                    </a>
                                </div>
                                <div style={{
                                    width: '25%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: 'white',
                                        fontFamily: 'Russo One, Sans-serif',
                                        fontWeight: '800',
                                        fontSize: '0.9vw',
                                        textDecoration: 'none',
                                        textAlign: 'left',
                                        marginBottom: '0.5vw'
                                    }}>
                                        CONTACT US
                                    </span>
                                        <Button shape='round' onClick={handleFeedback} style={{
                                            fontFamily: 'Russo One, Sans-serif',
                                            fontWeight: '550',
                                            height: '2.5vw',
                                            width: '10vw',
                                            background: 'white',
                                            backgroundSize: 'cover',
                                            color: 'black',
                                            border: 'none',
                                            marginTop: '1.1vw'

                                        }}><span style={{}}>Заповнити форму</span></Button>
                                </div>
                            </div>
                        </div>



                        {/*<div style={{*/}
                        {/*    width: '100%',*/}
                        {/*    height: '35%',*/}
                        {/*    display: 'flex',*/}
                        {/*    flexDirection: 'row'*/}
                        {/*}}>*/}
                        {/*    <div style={{*/}
                        {/*        height: '100%',*/}
                        {/*        width: '50vw',*/}
                        {/*        background: 'url(https://lh4.googleusercontent.com/proxy/dROITRabSsFFuvLliPzvZ_RQTXUe8cMysaR1yXgYMt2NiIqgoNQT_VB2CK6k4GnGKrGTX-A6at5ufWqWB5Rp3I5m17re0BGv)',*/}
                        {/*        backgroundSize: 'cover',*/}
                        {/*        display: 'flex',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*        alignItems: 'center'*/}
                        {/*    }}>*/}
                        {/*        <div style={{*/}
                        {/*            position: 'absolute',*/}
                        {/*            height: '42vh',*/}
                        {/*            width: '50vw',*/}
                        {/*            backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                        {/*        }}>*/}

                        {/*        </div>*/}
                        {/*        <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ629M7clq4510rRSd-kICQbMyYk8HYxTG0bA&s' style={{*/}
                        {/*            width: '12vw',*/}
                        {/*            zIndex: '2'*/}
                        {/*        }} />*/}
                        {/*    </div>*/}
                        {/*    <div style={{*/}
                        {/*        height: '100%',*/}
                        {/*        width: '50vw',*/}
                        {/*        background: 'url(https://kpi.ua/files/images-story/photo_2022-10-12%2018.58.03.jpeg)',*/}
                        {/*        backgroundSize: 'cover',*/}
                        {/*        display: 'flex',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*        alignItems: 'center'*/}
                        {/*    }}>*/}
                        {/*        <div style={{*/}
                        {/*            position: 'absolute',*/}
                        {/*            height: '42vh',*/}
                        {/*            width: '50vw',*/}
                        {/*            backgroundColor: 'rgba(0, 0, 0, 0.4)'*/}
                        {/*        }}>*/}

                        {/*        </div>*/}
                        {/*        <img src='https://upload.wikimedia.org/wikipedia/commons/4/4a/NTUU_KPI_logo.png' style={{*/}
                        {/*            width: '12vw',*/}
                        {/*            zIndex: '2'*/}
                        {/*        }} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>



                </>
            )
        } else{ //tablet
            return(
                <>
                    {fetchPreloaderMobile()}
                    {languageSelectorModalMobile()}
                    {feedbackFormModalMobile()}
                    {feedbackDoneModal()}
                    {mobileMenu()}
                    <div style={{
                        height: '5.5vh',
                        width: '100%',
                        backgroundColor: 'black',
                        zIndex: '999',
                    }}/>
                    <div style={{
                        height: '5.5vh',
                        position: 'fixed',
                        width: '100%',
                        backgroundColor: 'rgba(22, 22, 23, .8)',
                        zIndex: '1000',
                        marginTop: '-5.5vh',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Flex style={{
                            width: '100%',
                            marginTop: '-0.18vw'
                        }} justify="center" align="center">
                            <div style={{
                                width: '15%',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginLeft: '4vw'
                            }}>
                                {menuSelector()}

                            </div>

                            <div style={{
                                width: '70%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <a href='#'>
                                    <span style={{
                                        fontSize: '4vw',
                                        fontFamily: 'monospace',
                                        fontWeight: '200',
                                        letterSpacing: '0.4em',
                                        color: 'white',
                                        marginLeft: '0.4em'
                                    }}>
                                        Akson
                                    </span>
                                </a>
                            </div>

                            <div style={{
                                width: '15%',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                marginRight: '4vw'
                            }}>
                                <a>
                                    <img onClick={handleLanguageMobile} height='18vw' style={{

                                    }} src={ua}/>
                                </a>
                            </div>
                        </Flex>
                    </div>

                    <Carousel style={{
                        zIndex: '3',
                        marginBottom: '5vw',
                        height: '60vh'
                    }}
                              className="carousel-container" autoplay autoplaySpeed={3000}>
                        <div>
                            <div style={{
                                width: '100%',
                                height: '60vh',
                                backgroundImage: 'url(' + img10 +')',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vh',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}>Інноваційні рішення</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '60vh',
                                backgroundImage: 'url(https://cdn11.bigcommerce.com/s-bfxxgrup/images/stencil/1000x1000/uploaded_images/server-technician.jpg?t=1661350163)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vh',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}>Автоматизація процесів</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '60vh',
                                backgroundImage: 'url(' + img11 + ')',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vh',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}>Використання штучного ітелекту</h1>
                            </div>
                        </div>
                        <div>
                            <div className='abc' style={{
                                width: '100%',
                                height: '60vh',
                                backgroundImage: 'url(https://assets.techrepublic.com/uploads/2022/10/tr-shouldnt-virtualize.jpeg)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <h1 style={{
                                    color: 'white',
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '3.5vh',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.6)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}>Хмарні технології</h1>
                            </div>

                        </div>
                    </Carousel>

                    <div id='audit' style={{
                        width: '100%',
                        height: '105vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1wv'
                    }}>
                        <div style={{
                            width: '90%',
                            height: '100%',
                            background: 'linear-gradient(to right, #edf1f5, #eef2f3)',
                            backgroundSize: 'cover',
                            borderRadius: '3vw',
                            padding: '2em',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span style={{
                                width: '100%',
                                fontFamily: 'Russo One, Sans-serif',
                                fontSize: '7.5vw',
                                fontWeight: '600'
                            }}>
                                Системний аудит
                            </span>
                            <span style={{
                                width: '100%',
                                fontFamily: 'Russo One, Sans-serif',
                                fontSize: '5vw',
                                fontWeight: '600',
                                marginTop: '0.5em'
                            }}>
                                Збільшення доходу компанії за рахунок виявлення та автоматизації процесів, що цього потребують.
                            </span>
                            <div style={{
                                height: '100%'
                            }}>

                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end'
                            }}>
                                <Button className='rotateContent' onClick={() => {
                                    setFeedbackFormMobile(true)
                                }} style={{
                                    fontSize: '4.7vw',
                                    fontFamily: 'Russo One, Sans-serif',
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    width: '13em',
                                    height: '2.5em',
                                    border: '2px solid black',
                                    color: 'black',
                                }} shape='round'>
                                    Замовити аудит
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '100vw',
                        height: '100vw',
                        marginTop: '5vw'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '50%',
                            width: '100%'
                        }}>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                background: '#eef2f3',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '4.5vw',
                                    fontWeight: '600',
                                }}>
                                    ЗБІР ІНФОРМАЦІЇ
                                </span>
                            </div>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                background: 'black', // #434343
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '4.5vw',
                                    fontWeight: '600',
                                    color: 'white'
                                }}>
                                    АНАЛІЗ І ОЦІНКА
                                </span>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '50%',
                            width: '100%'
                        }}>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                background: 'black', // #434343
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '4.5vw',
                                    fontWeight: '600',
                                    color: 'white',
                                    textAlign: 'center'
                                }}>
                                    ВИКОНАННЯ РЕКОМЕНДАЦІЙ
                                </span>
                            </div>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                background: '#eef2f3',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    fontFamily: 'Russo One, Sans-serif',
                                    fontSize: '4.5vw',
                                    fontWeight: '600'
                                }}>
                                    ПІДГОТОВКА ЗВІТУ
                                </span>
                            </div>
                        </div>
                    </div>

                    <div id='forecast' style={{
                        width: '100%',
                        height: '105vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            width: '90%',
                            height: '100%',
                            background: 'linear-gradient(to right, #edf1f5, #eef2f3)',
                            backgroundSize: 'cover',
                            borderRadius: '3vw',
                            padding: '2em',
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '10vw'
                        }}>
                            <span style={{
                                width: '100%',
                                fontFamily: 'Russo One, Sans-serif',
                                fontSize: '7.5vw',
                                fontWeight: '600'
                            }}>
                                Прогнозування розвитку бізнесу
                            </span>
                            <span style={{
                                width: '100%',
                                fontFamily: 'Russo One, Sans-serif',
                                fontSize: '5vw',
                                fontWeight: '600',
                                marginTop: '0.5em'
                            }}>
                                Аналіз та прогнозування розвитку бізнесу є критично важливими для успішного стратегічного управління.
                            </span>
                            <div style={{
                                height: '100%'
                            }}>

                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end'
                            }}>
                                <Button className='rotateContent' onClick={() => {
                                    setFeedbackFormMobile(true)
                                }} style={{
                                    fontSize: '4.7vw',
                                    fontFamily: 'Russo One, Sans-serif',
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    width: '13em',
                                    height: '2.5em',
                                    border: '2px solid black',
                                    color: 'black',
                                }} shape='round'>
                                    Замовити прогнозування
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        height: '180vw',
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '20vw'
                    }}>
                        <div style={{
                            height: '100%',
                            width: '30%',
                            // background: 'red',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <div style={{
                                width: '1%',
                                height: '100%',
                                border: '1px solid white'
                            }}>
                                <div style={{
                                    width: '10vw',
                                    height: '10vw',
                                    borderRadius: '10vw',
                                    background: 'white',
                                    position: 'absolute',
                                    marginLeft: '-5vw',
                                    marginTop: '-1vw'
                                }}>

                                </div>

                                <div style={{
                                    width: '10vw',
                                    height: '10vw',
                                    borderRadius: '10vw',
                                    background: 'white',
                                    position: 'absolute',
                                    marginLeft: '-5vw',
                                    marginTop: '43vw'
                                }}>

                                </div>

                                <div style={{
                                    width: '10vw',
                                    height: '10vw',
                                    borderRadius: '10vw',
                                    background: 'white',
                                    position: 'absolute',
                                    marginLeft: '-5vw',
                                    marginTop: '76vw'
                                }}>

                                </div>

                                <div style={{
                                    width: '10vw',
                                    height: '10vw',
                                    borderRadius: '10vw',
                                    background: 'white',
                                    position: 'absolute',
                                    marginLeft: '-5vw',
                                    marginTop: '119.5vw'
                                }}>

                                </div>
                            </div>
                        </div>
                        <div style={{
                            height: '100%',
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span style={{
                                fontSize: '5.5vw',
                                fontWeight: '600',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Розробка стратегій
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Визначення стратегічних цілей на основі аналізу та прогнозування.
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Розробка довгострокових і короткострокових стратегій для досягнення поставлених цілей.
                            </span>

                            <span style={{
                                fontSize: '5.5vw',
                                fontWeight: '600',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '5vw'
                            }}>
                                Планування ресурсів
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw',
                                width: '90%'
                            }}>
                                Визначення необхідних ресурсів для реалізації стратегій.
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Розподіл ресурсів.
                            </span>

                            <span style={{
                                fontSize: '5.5vw',
                                fontWeight: '600',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '5vw'
                            }}>
                                Моніторинг і контроль
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw',
                                width: '90%'
                            }}>
                                Регулярний моніторинг виконання стратегій та планів.
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Внесення корективів на основі змін у внутрішньому та зовнішньому середовищі.
                            </span>

                            <span style={{
                                fontSize: '5.5vw',
                                fontWeight: '600',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '5vw'
                            }}>
                                Оцінка ризиків
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw',
                                width: '90%'
                            }}>
                                Визначення потенційних ризиків і розробка планів щодо їх мінімізації.
                            </span>
                            <span style={{
                                fontSize: '4vw',
                                fontWeight: '400',
                                fontFamily: 'Russo One, Sans-serif',
                                color: 'white',
                                marginTop: '0.5vw'
                            }}>
                                Використання методів ризик-менеджменту для забезпечення стабільного розвитку.
                            </span>
                        </div>
                    </div>

                    {/*<div style={{*/}
                    {/*    background: 'linear-gradient(to right, #edf1f5, #eef2f3)',*/}
                    {/*    backgroundSize: 'cover',*/}
                    {/*    width: '90vw',*/}
                    {/*    height: '55vh',*/}
                    {/*    borderRadius: '1vw',*/}
                    {/*    zIndex: '2',*/}
                    {/*    marginTop: '-6vh',*/}
                    {/*    textAlign: 'center',*/}
                    {/*    padding: '3em',*/}
                    {/*    paddingTop: '3em',*/}
                    {/*    display: 'flex',*/}
                    {/*    flexDirection: 'column',*/}
                    {/*    fontSize: '1em'*/}
                    {/*}}>*/}
                    {/*    { card1Engine() }*/}


                    {/*    /!*<span style={{*!/*/}
                    {/*    /!*    fontSize: '1.4em',*!/*/}
                    {/*    /!*    fontFamily: 'Russo One, Sans-serif',*!/*/}
                    {/*    /!*    // color: '#5A5A5A',*!/*/}
                    {/*    /!*    textAlign: 'left'*!/*/}
                    {/*    /!*}}>*!/*/}
                    {/*    /!*    <span style={{ fontSize: '1.2em', fontWeight: '550' }}>Переваги системного аудиту</span><br/><span style={{ fontWeight: '550' }}>Виявлення</span> слабких місць і рекомендації щодо їх усунення.<br/>*!/*/}
                    {/*    /!*    <span style={{ fontWeight: '550' }}>Ідентифікація і мінімізація</span> ризиків, пов'язаних з діяльністю організації.<br />*!/*/}
                    {/*    /!*    <span style={{ fontWeight: '550' }}>Підтвердження</span> прозорості і надійності систем управління організації для зацікавлених сторін.*!/*/}
                    {/*    /!*</span>*!/*/}
                    {/*</div>*/}
                </>
            )
        }
    }
}

export default HomeUA;